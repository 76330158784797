<script>
import { bus } from "../../main";

import ModalDefault_desktop from "./ModalDefault_desktop.vue";
import ModalDefault_mobile from "./ModalDefault_mobile.vue";
import ModalMedia from "./ModalMedia.vue";

export default {
  name: "Content",
  props: {},
  methods: {
    show(id) {
      const data = this._vaData.contents.find(d => {
        return d.id === id;
      });
      if (!data) {
        this.$notify({
          type: "error",
          title: "Content with ID " + id + " not found!",
          text: "Perhaps it has been deleted."
        });
        return false;
      }

      const isSmall = window.innerWidth < 960;
      console.log(data.type);
      let component = false;

      switch (data.type) {
        case "media":
          component = ModalMedia;
          console.log(component);
          break;
        default:
          // Sidebar + (Slideshow incl. Body)
          component = isSmall ? ModalDefault_mobile : ModalDefault_desktop;
      }

      this.$modal.show(
        component,
        data,
        {
          name: "content-modal",
          classes: `c-va_modal c-va_modal--${data.type} ${
            isSmall ? "c-va_modal--isSmall" : "modal-window-wrapper"
          }`,
          height: isSmall || id == 999 ? "auto" : "100%",
          width: isSmall ? "100%" : "100%",
          scrollable: isSmall ? true : false,
          shiftY: 0.3
        },
        {
          "before-close": () => {
            const params = new URLSearchParams(location.search);
            params.delete("content");
            window.history.replaceState({}, "", `?${params}`);
          }
        }
      );
    }
  },
  created() {
    bus.$on("show_content", id => {
      this.show(id);
      const params = new URLSearchParams(location.search);
      params.set("content", id);
      window.history.replaceState({}, "", `?${params}`);
    });
  },
  render() {
    return false;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "index.scss";
</style>
