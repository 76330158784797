<template>
  <div class="footer">
    <div>
      <!-- left -->
      <transition
        name="fade"
        enter-active-class="fadeInUp"
        leave-active-class="fadeOutDown"
      >
        <div
          v-show="!isHome"
          class="footer__button icon-button icon-button--home"
          @click="goHome"
        ></div>
      </transition>
      <Navigation class="footer__button" />
    </div>
    <div>
      <!-- center -->
    </div>
    <div>
      <!-- right -->
    </div>
  </div>
</template>
<script>
import { bus } from "../main";
import Navigation from "./Navigation.vue";
export default {
  name: "Footer",
  components: {
    Navigation
  },
  data() {
    return {
      isHome: true,
      showBack: false,
      strings: this._vaData.strings
    };
  },
  mounted() {
    bus.$on("location_reached", (id, history) => {
      const location = this._vaData.locations.find(l => l.id === id);
      this.showBack = location.show_back_btn && history.length > 0;
      this.isHome = this._vaData.settings.start_location === id;
    });

    bus.$on("location_left", () => {
      this.showBack = false;
    });
  },
  props: {},
  computed: {},
  methods: {
    goBack: () => {
      bus.$emit("go_back");
    },
    goHome() {
      bus.$emit("go_to_location", this._vaData.settings.start_location);
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  // background: rgba(#fff, 0.2);
  z-index: 100;
  padding: 0 30px;

  display: flex;
  justify-content: space-between;

  @media (max-width: $breakpoint-large) {
    display: none;
  }

  & > div {
    display: flex;
  }
}

.footer__button {
  background-color: $global-emphasis-color;
  border-left: 1px solid rgba(#fff, 0.1);
  border-right: 1px solid rgba(#000, 0.1);
  text-align: center;
  box-sizing: border-box;
  transition: all 0.2s;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 100%;

  &:last-child() {
    border-right: 0;
  }
  &:first-child() {
    border-left: 0;
  }

  &:hover {
    background-color: darken($global-emphasis-color, 5);
  }

  ::v-deep .trigger {
    padding: 0 20px;
  }
}
.icon-button {
  width: 50px;
}
.icon-button--home {
  background-size: auto 64%;
  background-image: url(../assets/arrow_left.svg);
}
</style>
