<template lang="html">
  <div>
    <div class="trigger">{{ strings.locations }}</div>
    <div
      ref="dropDown"
      uk-drop="mode: click; animation: uk-animation-slide-bottom-small; offset: 15; pos: top-right;"
    >
      <div class="menu uk-box-shadow-small">
        <div
          @click="goToLocation(location.id)"
          class="menu__entry"
          v-for="(location, index) in locations"
          :key="index"
        >
          {{ location.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../main";
import UIkit from "uikit";
export default {
  name: "Navigation",
  data() {
    return {
      strings: this._vaData.strings,
      locations: this._vaData.locations.sort((a, b) =>
        a.order > b.order ? 1 : -1
      )
    };
  },

  methods: {
    goToLocation(id) {
      bus.$emit("go_to_location", id);
      UIkit.drop(this.$refs.dropDown).hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.trigger {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 50;
}
.uk-drop {
  z-index: 49;
  width: auto;
}
.menu {
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  text-align: left;
  text-transform: uppercase;
}
// .menu:first-child {
//   color: yellow;
// }
.menu > div:nth-of-type(1) {
  color: $global-emphasis-color;
}

.menu__entry {
  padding: 0.5em 1em;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: rgba(#e2002b, 0.025);
  }
}
</style>
