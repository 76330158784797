<template lang="html">
  <div
    class="button-container"
    :style="
      `justify-content: ${justify}; align-items: ${align}; bottom: ${config.position.y}%; left: ${config.position.x}%;`
    "
  >
    <div
      ref="elem"
      class="button animate__animated animate__fadeInDown"
      :class="config.classes"
      :title="titleText.replace(/(<([^>]+)>)/gi, ' ')"
      data-type="hotspot"
      :style="
        `--animate-delay:${config.animation.delay}ms; --animate-duration:500ms; --animate-repeat: ${config.animation.repeat};`
      "
      :data-entrance-animation="config.animation.entrance"
      :data-exit-animation="config.animation.exit"
    >
      <span class="button__text">{{ titleText }}</span>
    </div>

    <div
      uk-drop="mode: click; animation: uk-animation-slide-top-small; offset: 5; pos: top-center"
    >
      <div class="menu uk-box-shadow-small">
        <div
          class="menu__entry"
          v-for="product in products"
          :key="product.slug"
          @click="showContent(product.slug)"
        >
          {{ product.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../main";

export default {
  props: {
    id: {
      type: Number
    },
    config: {
      config: Number
    },
    title: {
      type: String,
      default: "Info"
    },
    productGroups: {
      type: Object
    }
  },
  data() {
    return {
      titleText:
        this.$props.title.length > 0
          ? this.$props.title
          : this.productGroups.title,
      justify: (() => {
        let value = "center";
        if (
          this.config.position.origin &&
          this.config.position.origin.match(
            /^([center|left|right]+)-([center|bottom|top]+)$/
          )
        ) {
          switch (this.config.position.origin.split("-")[0]) {
            case "center":
              value = "center";
              break;
            case "left":
              value = "flex-start";
              break;
            case "right":
              value = "flex-end";
              break;
            default:
              value = "center";
          }
        }
        return value;
      })(),
      align: (() => {
        let value = "center";
        if (
          this.config.position.origin &&
          this.config.position.origin.match(
            /^([center|left|right]+)-([center|bottom|top]+)$/
          )
        ) {
          switch (this.config.position.origin.split("-")[1]) {
            case "center":
              value = "center";
              break;
            case "top":
              value = "flex-start";
              break;
            case "bottom":
              value = "flex-end";
              break;
            default:
              value = "center";
          }
        }
        return value;
      })(),
      products: this._vaData.productGroups.find(
        g => g.id === this.productGroups.id
      ).products
    };
  },
  mounted() {
    this.container = this.getTargetContainer();
    this.container.appendChild(this.$el);
  },
  methods: {
    showContent(id) {
      bus.$emit("show_content", id);
    },
    async remove() {
      await this.hide();
      this.$el.remove();
    },
    hide() {
      return new Promise(resolve => {
        const $elem = this.$refs.elem;
        const exitAnimation = $elem.dataset.exitAnimation;
        $elem.addEventListener("animationend", () => {
          $elem.remove();
          resolve();
        });
        $elem.classList.add("animate__" + exitAnimation);
      });
    },
    getTargetContainer(relativeTo = "video") {
      if (relativeTo === "video") {
        return document.querySelector(".videothing .controls--video_relative");
      } else {
        return document.querySelector(
          ".videothing .controls--viewport_relative"
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  z-index: 50;
  // &:after {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   margin: auto;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   height: 0;
  //   width: 0;
  //   background: #e2002b;
  //
  //   clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
  //   transition: all 0.2s;
  // }
  // &.uk-open:after {
  //   height: 10px;
  //   width: 20px;
  //   bottom: -9px;
  // }
}

.uk-drop {
  z-index: 49;
  width: auto;
}
.menu {
  background: #fff;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  min-width: 300px;
}

.menu__entry {
  white-space: normal;
  line-height: 18px;
  text-transform: uppercase;
  padding: 0.4em 1em;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: rgba(#e2002b, 0.025);
  }
}
</style>
