<template>
  <div>
    <div class="c-va_modal__header">
      <!-- <img class="header_flag" src="../../assets/esylux_flag.svg" /> -->
      <!-- <div class="modal__header">
        <h2 class="header_title" v-if="header_title" v-html="header_title"></h2>
        <h5
          class="header_subtitle"
          v-if="header_subtitle"
          v-html="header_subtitle"
        ></h5>
      </div> -->
      <button
        class="vm-modal__close"
        @click="$emit('close')"
        :title="strings.close"
      >
        <span uk-icon="icon: close; ratio: 2"></span>
      </button>
    </div>
    <div class="c-va_modal__container">
      <div class="aside">
        <div class="aside__header">
          <h2 v-if="title" v-html="title"></h2>
          <h5 v-if="subtitle" v-html="subtitle"></h5>
        </div>

        <vue-custom-scrollbar
          :settings="scrollbar.settings"
          class="aside__body"
        >
          <div v-if="body">
            <vue-markdown>
              {{ body }}
            </vue-markdown>
          </div>
        </vue-custom-scrollbar>

        <div class="modal_contact_padding">
          <div
            class="uk-grid uk-grid-small uk-child-width-expand uk-margin-remove-left"
            uk-grid
          >
            <div v-if="product_link" class="finder_link__button uk-button">
              <a
                class="finder_link uk-button"
                :href="product_link"
                target="_blank"
                title="Produktlink"
                ><span class="margin-small-right"></span
                >{{ strings.product_link }}</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="main">
        <VueSlickCarousel
          v-bind="slickSettings"
          @beforeChange="beforeChangeCarousel"
          @afterChange="afterChangeCarousel"
          @init="onInitCarousel"
          ref="carousel"
        >
          <Slide v-for="slide in slides" :key="slide.id" :slide="slide"></Slide>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import Slide from "./Slide.vue";
// import CallToContact from "./CallToContact.vue";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

// optional style for arrows & dots
export default {
  name: "ModalDefault_desktop",
  data: function() {
    return {
      strings: this._vaData.strings,
      slickSettings: {
        dots: true,
        arrows: true
      },
      slideIndex: 0,
      scrollbar: {
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: false
        }
      }
    };
  },
  props: {
    title: String,
    subtitle: String,
    header_title: String,
    header_subtitle: String,
    category: Object,
    body: String,
    slides: Array,
    product_link: String,
    link_list: Array
  },
  components: {
    VueSlickCarousel,
    Slide,
    vueCustomScrollbar
    // CallToContact
  },
  created() {},
  mounted() {
    // open all links in modal in new tab
    document.querySelectorAll(".c-va_modal__container a").forEach($link => {
      $link.addEventListener("click", e => {
        e.preventDefault();
        window.open(e.target.href);
        return;
      });
    });
    document
      .querySelectorAll(".c-va_modal__container video")
      .forEach($video => {
        // hide controls of all videos
        $video.controls = "";
      });
    if (document.querySelector(".slick-current video")) {
      document.querySelector(".slick-current video").controls = "controls";
    }
  },
  methods: {
    onInitCarousel() {},
    afterChangeCarousel() {
      const $currentSlide = this.$refs.carousel.$el.querySelector(
        ".slick-current"
      );
      const $video = $currentSlide.querySelector("video");
      if ($video) {
        // show controls
        $video.controls = "controls";
      }
    },
    beforeChangeCarousel() {
      const $currentSlide = this.$refs.carousel.$el.querySelector(
        ".slick-current"
      );
      const $video = $currentSlide.querySelector("video");
      const $iframe = $currentSlide.querySelector("iframe");

      if ($video) {
        $video.pause();
        // hide controls
        $video.controls = "";
      }
      if ($iframe) {
        const src = $iframe.getAttribute("src");
        if (src.includes("youtube")) {
          $iframe.contentWindow.postMessage(
            '{"event":"command","func":"stopVideo","args":""}',
            "*"
          );
        }
      }
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.c-va_modal {
  & > div,
  .c-va_modal__container {
    height: 100%;
    overflow: hidden;
  }

  .aside {
    @extend .uk-width-1-3,
      .uk-margin-small-right,
      .uk-box-shadow-medium,
      .uk-flex,
      .uk-flex-column;
    background: #fff;

    height: 95%;
  }

  .aside__header {
    @extend .uk-padding;
    color: $global-emphasis-color;
    font-weight: 400;
    position: relative;
    z-index: 1;

    h2 {
      margin: 0;
      text-transform: uppercase;
    }

    h5 {
      margin: 0.5em 0 0;
      text-transform: uppercase;
    }
  }

  .aside__body {
    @extend .uk-padding, .uk-flex-1;
    padding-top: 0px;
    height: 100%;
    overflow-y: scroll;
    font-family: "esylux", arial, sans-serif;
    font-size: 13px;
    line-height: 1.5;
  }

  .aside__footer {
    position: relative;
    z-index: 1;
    @extend .uk-padding;
    padding-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid $global-muted-color;
    background-color: #efefef;
  }

  .main {
    @extend .uk-width-expand, .uk-background-default, .uk-box-shadow-medium;

    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-slide {
      & > div,
      .slide {
        background: #fff;
        height: 100%;
      }
    }

    .slide {
    }
  }
}
.finder_link__button {
  @extend .uk-background-default;
  @extend .uk-padding;
  a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 0.9em;
    line-height: 1.4;
    width: 100%;
    margin: 10px 0;
  }
  button {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 0.9em;
    line-height: 1.4;
    width: 100%;
    margin: 30px 0;
  }
  @media (max-width: 1200px) {
    a {
      font-size: 12px;
    }
    button {
      font-size: 12px;
    }
  }
  @media (max-width: 1100px) {
    a {
      font-size: 10px;
    }
    button {
      font-size: 10px;
    }
  }
}
.finder_link {
  @extend .uk-button, .uk-button-primary, .uk-button-large;
}
.modal_contact_padding {
  padding: 20px 30px;
}
.modal_number {
  color: $global-muted-color;
  font-size: 14px !important;
  pointer-events: none;
  padding: 0 !important;
}
.padding-small {
  padding: 15px !important;
}
.margin-small-right {
  margin-right: 10px !important;

  @media (max-width: 1200px) {
    margin-right: 5px !important;
  }
  @media (max-width: 1100px) {
    margin-right: 0px !important;
  }
}
.c-va_modal__header {
  background-color: #fff;
  width: 100%;
  height: calc(5% - 5px);
  height: -webkit-calc(5% - 5px);
  height: -moz-calc(5% - 5px);
  height: -o-calc(5% - 5px);
  margin-bottom: 5px;
  position: relative;
}
.header_flag {
  width: 41px;
  float: left;
  position: absolute;
  bottom: 0px;

  @media (max-height: 800px) {
    width: 34px;
  }
  @media (max-height: 700px) {
    width: 32px;
  }
}
.header_title {
  margin: 0;
  color: #000;
  text-transform: uppercase;
  line-height: 1em;
  font-size: 2rem;
  @media (max-height: 800px) {
    font-size: 1.7rem;
  }
  @media (max-height: 700px) {
    font-size: 1.5rem;
    line-height: 0.8em;
  }
}
.header_subtitle {
  margin-top: 0;
  color: #000;
  font-size: 1.6em;
  text-transform: uppercase;
  margin-bottom: 15px;
  @media (max-height: 800px) {
    font-size: 1.3rem;
  }
  @media (max-height: 700px) {
    font-size: 1.2rem;
  }
}
.modal__header {
  position: absolute;
  bottom: 0;
  left: 5%;
  @media (max-height: 800px) {
    bottom: -3px;
  }
}
.vm-modal__close {
  width: 25px !important;
  height: 25px !important;
  padding: 0.1em !important;

  @media (max-width: 800px) {
    width: 25px !important;
    height: 25px !important;
    padding: 0.2em !important;
  }
}
</style>
