<template>
  <div class="contactsMenu">
    <div class="contactsMenu__inner">
      <a
        class="button"
        v-if="strings.website_url"
        :href="strings.website_url"
        target="_blank"
      >
        <i class="button__icon button__icon--website"></i>
        <span class="button__text">{{
          strings.website_text || "Website"
        }}</span>
      </a>
      <a
        class="button"
        v-if="strings.newsletter_url"
        :href="strings.newsletter_url"
        target="_blank"
      >
        <i class="button__icon button__icon--newsletter"></i>
        <span class="button__text">
          {{ strings.newsletter_text || "Newsletter" }}
        </span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContactMenu",
  data: function() {
    return {
      strings: this._vaData.strings
    };
  },
  props: {},
  methods: {},
  created() {}
};
</script>
<style lang="scss" scoped>
.contactsMenu {
  position: fixed;
  z-index: 100;
  right: 0;
  top: 0;
  bottom: 0;
  height: 0;
  width: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: auto;

  @media (max-width: $breakpoint-large) {
    display: none;
  }
}

.contactsMenu__inner {
  display: flex;
  flex-direction: column;
}

.button {
  --height: 50px;
  --textbox-width: 180px;
  margin: 1px 0;
  background-color: $global-emphasis-color;
  color: #fff;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: var(--height) var(--textbox-width);
  height: var(--height);
  align-items: center;

  transition: all 0.3s;
  transform: translateX(var(--textbox-width));
  &:hover {
    transform: translateX(0);
    margin-right: 0px;
    text-decoration: none;
    color: #fff;
  }
}
.button__text {
  width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  padding: 15px;
  text-transform: uppercase;
}

.button__icon {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
}

.button__icon--website {
  background-image: url(../assets/world.svg);
}
.button__icon--newsletter {
  background-size: 60%;
  background-image: url(../assets/newsletter.svg);
}
</style>
